// Global
import "./modules/bootstrap";
import "./modules/theme";
import "./modules/dragula";
import "./modules/feather";
import "./modules/moment";
import "./modules/sidebar";
import "./modules/notyf";

// Charts
import "./modules/chartjs";
import "./modules/apexcharts";

// Forms
import "./modules/daterangepicker"; // requires jQuery
import "./modules/datetimepicker"; // requires jQuery
import "./modules/fullcalendar";
import "./modules/mask"; // requires jQuery
import "./modules/quill";
import "./modules/select2"; // requires jQuery
import "./modules/validation"; // requires jQuery
import "./modules/wizard"; // requires jQuery

// Maps
import "./modules/vector-maps";

// Tables
import "./modules/datatables"; // requires jQuery

$('.generate-password').click(function () {
    let obj = $(this);
    $.post(obj.attr('href'), {
        '_token' : $('body').data('token'),
        'length' : obj.data('length')
    }).done(function(response) {
        $(obj.data('placeholder'))
            .html(response)
            .show();

        $(obj.data('inputs'))
            .val(response)
    });

    return false;
});

$('.save-collapse').click(function () {
    let obj = $(this);
    $.post($('body').data('base-url') + 'collapse', {
        '_token' : $('body').data('token') ,
        'key' : obj.data('save-collapse-key')
    });
});

$('.read-at').click(function () {
    let obj = $(this);
    $.post(obj.data('post'), {
        '_token' : $('body').data('token')
    }).done(function (response) {
        $('.read-at-' + response.id).remove();
    });
});

$(document.body).on('click', '.x-user-search-component', function () {

    let el = $(this);
    let request = function () {
        $('#user-search-modal').modal('show');
        $('#user-search-ajax').html($('#spinner').html());
        $.post(el.data('index'), {
            '_token' : $('body').data('token'),
            'scope'  : el.data('scope'),
            'search' : $('#user-search-modal input[name=search]').val()
        }).done(function(response) {
            $('.x-spinner-component').fadeOut(400, function () {
                $('#user-search-ajax').html(response);
            });
        });
    };

    if(!$('#user-search-modal').length) {
        $.get(el.data('modal')).done(function(response) {
            $('body').append(response);
            feather.replace();
            request();
        });
    } else {
        request();
    }
});

function serviceStatusWidget() {
    $.post($('#service-status-widget').data('post'), {
        '_token' : $('body').data('token')
    }).done(function(response) {
        $('#service-status-widget').html(response);
    });
}

serviceStatusWidget();
setInterval(function() {
    serviceStatusWidget()
}, 60 * 1000);
